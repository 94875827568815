<template>
  <!--编辑外部维修部门-->
  <div class="page">
    <van-nav-bar
      fixed
      title="上传外部维修单位"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="container">
      <van-form ref="form" class="form">
        <div class=" equipment-title inside-title">
          <span>维修部门</span>
          <span class="equipment-title-name">{{ form.departmentName }}</span>
        </div>
        <div>
          <van-field
            :value="(form.maintenanceUserName || []).toString()"
            clickable
            name="maintenanceUserName"
            label="维修人"
            placeholder="请选择"
            clearable
            input-align="right"
            error-message-align="right"
            @click="maintainPersonSelect()"
          />
          <van-field
            v-model="form.startDate"
            readonly
            clickable
            required
            name="startDate"
            label="开始时间"
            placeholder="请选择"
            input-align="right"
            error-message-align="right"
            :rules="[
              {
                required: true,
                message: ' ',
                trigger: 'onChange'
              },
              {
                validator: startValidator,
                message: '开始时间不能晚于结束时间',
                trigger: 'onChange'
              }
            ]"
            @click="planTimeClick('startDate')"
          />
          <van-field
            v-model="form.endDate"
            readonly
            clickable
            required
            name="endDate"
            label="结束时间"
            placeholder="请选择"
            input-align="right"
            error-message-align="right"
            :rules="[
              {
                required: true,
                message: ' ',
                trigger: 'onChange'
              },
              {
                validator: endValidator,
                message: '结束时间不能早于开始时间',
                trigger: 'onChange'
              }
            ]"
            @click="planTimeClick('endDate')"
          />
          <van-field
            v-model="form.reservePartNeed"
            clickable
            name="reservePartNeed"
            label="备件需求"
            placeholder="请输入备件需求"
            input-align="right"
            error-message-align="right"
            maxlength="500"
            show-word-limit
          />

          <van-field
            v-model="form.workTime"
            clickable
            name="workTime"
            label="工时(人*小时)"
            label-width="100"
            placeholder="请输入内容"
            clearable
            input-align="right"
            error-message-align="right"
            :rules="[
              {
                pattern: /^\d{0,50}$|^\d{0,48}\.\d{0,2}$/,
                message: '仅能输入数字，允许输入小数点后两位',
                trigger: 'onChange'
              }
            ]"
          />
          <van-field
            v-model="form.cost"
            clickable
            name="cost"
            label="维修费用(元)"
            placeholder="请输入内容"
            clearable
            input-align="right"
            error-message-align="right"
            :rules="[
              {
                pattern: /^\d{0,50}$|^\d{0,48}\.\d{0,2}$/,
                message: '仅能输入数字，允许输入小数点后两位',
                trigger: 'onChange'
              }
            ]"
          />
          <van-field
            v-model="form.score"
            clickable
            name="score"
            label="评分"
            placeholder="请输入内容"
            maxlength="50"
            clearable
            input-align="right"
            error-message-align="right"
            :rules="[
              {
                pattern: /^\d{0,17}$|^\d{0,17}\.\d{0,2}$/,
                message: '仅能输入数字，允许输入小数点后两位',
                trigger: 'onChange'
              }
            ]"
          />
          <van-field
            v-model="form.processRecord"
            clickable
            required
            name="processRecord"
            label="配件更换"
            placeholder="请输入内容"
            maxlength="500"
            clearable
            input-align="right"
            error-message-align="right"
            :rules="[
              {
                required: true,
                message: ' ',
                trigger: 'onChange'
              }
            ]"
          />
          <van-field
            v-model="form.subsidiaryWork"
            clickable
            name="subsidiaryWork"
            label="辅助工作"
            placeholder="请输入内容"
            maxlength="500"
            clearable
            input-align="right"
            error-message-align="right"
          />
          <van-field
            v-model="form.maintenanceExplain"
            clickable
            name="maintenanceExplain"
            label="检修说明"
            placeholder="请输入内容"
            maxlength="500"
            clearable
            input-align="right"
            error-message-align="right"
          />
          <van-field
            v-model="form.maintenanceAnalyse"
            clickable
            name="maintenanceAnalyse"
            label="维修分析"
            placeholder="请输入内容"
            maxlength="500"
            clearable
            input-align="right"
            error-message-align="right"
          />

          <!-- 上传附件	 -->
          <fileUploaderEncap
            v-model="form.fileList"
            accept="*"
            :max-size="1024 * 1024 * 100"
            tip="格式不限,单个文件不超过100MB,仅可以上传5个文件"
          >
          </fileUploaderEncap>
        </div>
      </van-form>
      <bottom-fixed-box>
        <van-button @click="deleteExternalRepairs()">
          删除
        </van-button>
        <van-button type="info" @click="save()">
          保存
        </van-button>
      </bottom-fixed-box>
    </div>
    <select-popup-grade
      v-model="form.maintenanceUser"
      :immediately="false"
      multi
      title="维修人"
      :list.sync="maintainPersonSltData"
      :visible="maintainPersonVisiable"
      :props="{ label: 'contractorPersonName', value: 'id' }"
      popup-height="70%"
      @change="popupGradeChange"
      @close="maintainPersonVisiable = false"
    />
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="initTime"
        type="date"
        @confirm="onDateimeConfirm"
        @cancel="showTimePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import {
  getContractorsPersons,
  updateExternalRepairs,
  deleteExternalRepairs
} from "./api";
import fileUploaderEncap from "@/components/fileUploaderEncap.vue";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import { parseTime } from "@/utils";
import { updateMaintenanceRecord } from "@/api/psm/workorder";

export default {
  name: "MaintenanceExternalRepairsEdit",
  components: {
    fileUploaderEncap,
    BottomFixedBox
  },
  data() {
    return {
      form: {},
      maintainPersonSltData: [],
      maintainPersonVisiable: false,

      showTimePicker: false,
      planTimeCurProp: "",
      initTime: "",
      sparePartsVisiable: false
    };
  },
  created() {
    this.form = this.$route.params.contractorInfo;
    this.getContractorsPersons();
  },
  methods: {
    save() {
      this.$refs.form.validate().then(async () => {
        let params = {
          ...this.form,
          ticketsMaintenanceRecordId: this.$route.params.recordId,
          ticketId: this.$route.params.workorderId,
          maintenanceUser: this.form.maintenanceUser?.toString(),
          maintenanceUserName: this.maintenanceUserName?.toString()
        };
        await updateExternalRepairs(this.form.id, params);
        this.$toast.success("保存成功");
        this.$router.back();
      });
    },
    deleteExternalRepairs() {
      this.$dialog
        .confirm({
          title: "提醒",
          message: "确认删除？"
        })
        .then(async () => {
          deleteExternalRepairs(this.form.id).then(res => {
            this.$toast.success("删除成功");
            this.$router.back();
          });
        });
    },
    // 通过承包商查询承包商人员
    getContractorsPersons() {
      getContractorsPersons({
        contractorId: this.form.contractorId,
        state: 0
      }).then(res => {
        console.log(res);
        this.maintainPersonSltData = res;
      });
    },
    maintainPersonSelect() {
      this.maintainPersonVisiable = true;
    },
    popupGradeChange(v) {
      this.form.maintenanceUser = v.map(i => i.value);
      this.form.maintenanceUserName = v.map(i => i.label);
    },
    planTimeClick(prop) {
      this.planTimeCurProp = prop;
      const datetime = this.form[prop];
      this.initTime = datetime ? new Date(datetime) : new Date();
      this.showTimePicker = true;
    },
    startValidator(val) {
      if (!val) return true;
      const startTime = Date.parse(new Date(val));
      const endTime = Date.parse(new Date(this.form.endDate));
      return endTime >= startTime;
    },
    endValidator(val) {
      if (!val || !this.form.startDate) return true;
      const endTime = Date.parse(new Date(val));
      const startTime = Date.parse(new Date(this.form.startDate));
      return endTime >= startTime;
    },
    onDateimeConfirm(time) {
      this.form[this.planTimeCurProp] = parseTime(time, "{y}-{m}-{d}");
      this.showTimePicker = false;
    },
    sparePartsClick() {
      this.sparePartsVisiable = true;
    },
    sparePartsChange(v) {
      this.form.partList.push(
        ...v.filter(i => !this.form.partList.find(f => i.id === f.id))
      );
    },
    navBack() {
      this.$dialog
        .confirm({
          title: "提醒",
          message: "数据尚未保存，确认返回？"
        })
        .then(() => {
          this.$router.back();
        });
      return;
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  overflow: hidden;
}

.container {
  height: 100%;
  margin-top: 58px;
  overflow-y: auto;

  .form {
    padding-top: 12px;
  }

  .equipment-title {
    font-size: 16px;
    font-weight: 600;
    justify-content: left;
    padding: 0 0 10px 16px;

    .equipment-title-name {
      margin-left: 12px;
      font-size: 12px;
      color: #a0a2a6;
    }
  }
}
</style>
